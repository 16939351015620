import request from "@/utils/request"

export default {
	getMessages: data =>
		request("/messages", {
			method: "get",
			params: data
		}),
	getMessageInfo: data =>
		request(`/message/${data.messageId}`, {
			method: "get",
			params: data
		}),
	// 获取审批表单数据
	getExtraInfo: data =>
		request(`/flowable/todo/${data.id}/extra-info`, {
			method: "get"
		})
}
