<template>
	<div>
		<Collapse
			@on-change="handleOnChange"
			:value="infoHeight > 50 ? ['projectData'] : []"
			class="project-base-data"
		>
			<!--			<Panel name="projectData">-->
			<!--				<Tooltip-->
			<!--					:content="projectInfo.name"-->
			<!--					placement="bottom-start"-->
			<!--					style="width: calc(100% - 400px)"-->
			<!--					max-width="200"-->
			<!--				>-->
			<p class="project-name">{{ projectInfo.name || "--" }}</p>
			<!--				</Tooltip>-->
			<!--				<div class="project-to-do-button">-->
			<!--					<a-->
			<!--						href="javascript:;"-->
			<!--						v-if="projectInfo.terminateDate"-->
			<!--						style="-->
			<!--							cursor: default;-->
			<!--							margin-right: 10px;-->
			<!--							color: red;-->
			<!--							font-weight: 700;-->
			<!--						"-->
			<!--						>项目已终止</a-->
			<!--					>-->
			<!--					<Button-->
			<!--						type="primary"-->
			<!--						v-if="isShowCodeBtn && orgCode"-->
			<!--						style="margin-right: 8px; background: #fff; color: #fda21e"-->
			<!--						@click.stop="handleEditCode()"-->
			<!--					>-->
			<!--						编号修改-->
			<!--					</Button>-->
			<!--					<Button-->
			<!--						type="primary"-->
			<!--						:class="[{ 'complete-btn': !followCheckData.notifyType }]"-->
			<!--						style="margin-right: 8px"-->
			<!--						@click.stop="handleFollowCheck()"-->
			<!--					>-->
			<!--						跟踪审查-->
			<!--					</Button>-->
			<!--					<Button-->
			<!--						type="primary"-->
			<!--						v-if="env === 'xinhua' && isShowTermBtn"-->
			<!--						style="margin-right: 8px"-->
			<!--						@click.stop="handleTerminate()"-->
			<!--					>-->
			<!--						项目终止-->
			<!--					</Button>-->
			<!--					&lt;!&ndash;          <Button type="primary"&ndash;&gt;-->
			<!--					&lt;!&ndash;                  @click.stop="handleShowToDo()">&ndash;&gt;-->
			<!--					&lt;!&ndash;            待审批流程（{{projectToDoNum}}）&ndash;&gt;-->
			<!--					&lt;!&ndash;          </Button>&ndash;&gt;-->
			<!--				</div>-->

			<!--				<div slot="content">-->
			<!--					<Form :model="projectInfo" label-position="top">-->
			<!--						<Row :gutter="32">-->
			<!--							<Col span="8">-->
			<!--								<FormItem label="系统流水号">-->
			<!--									{{ projectInfo.serialNo || "&#45;&#45;" }}-->
			<!--								</FormItem>-->
			<!--							</Col>-->
			<!--							<Col span="8">-->
			<!--								<FormItem label="产品名称">-->
			<!--									{{ projectInfo.protNo || "&#45;&#45;" }}-->
			<!--								</FormItem>-->
			<!--							</Col>-->
			<!--							<Col span="8">-->
			<!--								<FormItem label="主要研究者">-->
			<!--									{{ projectInfo.researcher || projectInfo.piName || "&#45;&#45;" }}-->
			<!--								</FormItem>-->
			<!--							</Col>-->
			<!--						</Row>-->
			<!--					</Form>-->
			<!--				</div>-->
			<!--			</Panel>-->
		</Collapse>
		<!--项目终止-->
		<!--		<terminate-project-->
		<!--			:visible="terminateVisible"-->
		<!--			@onOk="handleTerminateOnOk"-->
		<!--			@onCancel="terminateVisible = false"-->
		<!--		></terminate-project>-->
		<!--		&lt;!&ndash;编号修改&ndash;&gt;-->
		<!--		<edit-code-->
		<!--			:visible="codeVisible"-->
		<!--			:orgCode="orgCode"-->
		<!--			@onOk="handleEditCodeOk"-->
		<!--			@onCancel="codeVisible = false"-->
		<!--		></edit-code>-->
		<!--		&lt;!&ndash;跟踪审查&ndash;&gt;-->
		<!--		<follow-check-->
		<!--			:visible="followCheckVisible"-->
		<!--			@onOk="handleFollowCheckOk"-->
		<!--			@onCancel="followCheckVisible = false"-->
		<!--		></follow-check>-->
		<!--		&lt;!&ndash;待办&ndash;&gt;-->
		<!--		<ToDoList-->
		<!--			:visible="toDoVisible"-->
		<!--			type="project"-->
		<!--			@onOk="handleOnOk"-->
		<!--			@onCancel="toDoVisible = false"-->
		<!--		></ToDoList>-->
	</div>
</template>

<script>
import ToDoList from "@/views/Workbench/components/ToDoList.vue"
import { mapState } from "vuex"
import { getEnumerateByKey } from "@/utils/util"
import api from "@/api/project/project"
import TerminateProject from "./TerminateProject.vue"
import FollowCheck from "./FollowCheck.vue"
import EditCode from "./EditCode.vue"

const { apiGetCode } = api

export default {
	name: "ProjectBaseData",
	components: {
		ToDoList,
		TerminateProject,
		FollowCheck,
		EditCode
	},
	data() {
		return {
			toDoVisible: false,
			terminateVisible: false,
			followCheckVisible: false,
			codeVisible: false
		}
	},
	computed: {
		...mapState("project", [
			"projectInfo",
			"infoHeight",
			"toDoTotal",
			"followCheckData",
			"orgCode"
		]),
		...mapState("process", ["projectToDoNum"]),
		...mapState("user", ["processList", "info"]),
		...mapState(["enumerate"]),
		...mapState("permission", ["projectActionPermissions"]),
		statusName() {
			return getEnumerateByKey(
				this.enumerate.PROJECT_STATUS,
				this.projectInfo.status
			).v
		},
		isShowTermBtn() {
			return (
				this.projectActionPermissions.indexOf("btn_project_termination") > -1 &&
				this.projectInfo.status !== 0 &&
				this.projectInfo.status !== 3 &&
				!this.projectInfo.terminateDate
			)
		},
		isShowCodeBtn() {
			return (
				this.env === "shenzhenrenmin" &&
				(this.info.id === "1" || this.info.id === "2")
			)
		},
		env() {
			return this.$store.state.environment
		},
		projectId() {
			return this.$route.params.projectId
		}
	},
	watch: {
		$route(val, oldVal) {
			if (val.params.projectId !== oldVal.params.projectId) {
				this.initInfo()
			}
		}
	},
	created() {
		this.initInfo()
	},
	methods: {
		// 获取项目信息
		initInfo() {
			this.$store
				.dispatch("project/getProjectInfo", this.projectId)
				.then(res => {
					// 获取进行中流程的数据（判断各类流程的状态）
					if (res) {
						this.$store.dispatch("project/getProjectProcessNum", this.projectId)
					}
				})
			this.$store.dispatch("project/getFollowCheck", this.projectId) // 获取跟踪审查信息
			this.$store.dispatch("project/getExistCode", this.projectId) // 获取编号修改信息
			this.$store.dispatch("process/getProjectToDoNum", this.projectId)
		},
		// 显示待办列表
		handleShowToDo() {
			this.toDoVisible = true
		},
		// 内部完成代办时，刷新代办列表
		handleOnOk() {
			// 更新待审批流程数量
			this.$store.dispatch("process/getProjectToDoNum", this.projectId)
			// 获取进行中流程的数据（判断各类流程的状态）
			this.$store.dispatch("project/getProjectProcessNum", this.projectId)
			// 更新项目信息（提交流程，项目状态改变）
			this.$store.dispatch("project/getProjectInfo", this.projectId)
			this.$store.dispatch("project/getFollowCheck", this.projectId) // 获取跟踪审查信息

			if (this.$route.name === "projectProcess") {
				this.$store.dispatch("user/getProcessList").then(() => {
					this.$store.dispatch("process/getProgressListData", {
						projectId: this.projectId,
						processList: this.processList
					})
				})
			}
		},
		// 项目终止
		handleTerminate() {
			this.terminateVisible = true
		},
		// 项目终止成功
		handleTerminateOnOk() {
			// 更新项目信息（提交流程，项目状态改变）
			this.$store.dispatch("project/getProjectInfo", this.projectId)
			this.terminateVisible = false
		},
		// 编号修改
		handleEditCode() {
			this.codeVisible = true
		},
		// 编号修改提交
		handleEditCodeOk() {
			this.codeVisible = false
			this.$store.dispatch("project/getExistCode", this.projectId) // 获取编号修改信息
			// 更新项目信息（提交流程，项目状态改变）
			this.$store.dispatch("project/getProjectInfo", this.projectId)
		},
		// 跟踪审查
		handleFollowCheck() {
			this.followCheckVisible = true
		},
		// 跟踪审查提交
		handleFollowCheckOk() {
			// 更新跟踪审查信息
			this.$store.dispatch("project/getFollowCheck", this.projectId) // 获取跟踪审查信息
			this.followCheckVisible = false
		},
		handleOnChange() {
			this.$emit("onChange")
		}
	}
}
</script>

<style lang="less">
.project-base-data {
	background-color: #fff;
	.ivu-form-item {
		margin-bottom: 0;
	}
	&.ivu-collapse {
		border: none;
		background-color: #fff;
		> .ivu-collapse-item {
			> .ivu-collapse-header {
				padding-bottom: 15px;
				padding-right: 16px;
				height: auto;
				border-bottom: 1px solid #eee;
			}
		}
		.project-name {
			line-height: 49px;
			font-size: 16px;
			font-weight: 600;
			padding-left: 20px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
	.ivu-form-item-label {
		font-size: 14px;
		color: #999;
	}
	.ivu-form-item-content {
		font-size: 16px;
		color: #202730;
	}
	.ivu-tooltip {
		height: 32px;
		line-height: 32px;
		vertical-align: middle;
		.ivu-tooltip-rel {
			width: 100%;
		}
	}
}

.project-to-do-button {
	float: right;
	margin-right: 3px;
	.complete-btn {
		background: #fff;
		color: #333;
		border-color: #333;
	}
}
</style>
