<template>
	<div class="project-router-wrap">
		<ProjectBaseData @onChange="onChange"></ProjectBaseData>
		<div class="project-content" :style="{ top: infoHeight + 'px' }">
			<!--		<div class="project-content">-->
			<transition name="slide-fade" mode="out-in">
				<router-view v-if="isHasProjectId" />
			</transition>
		</div>
	</div>
</template>

<script>
import ProjectBaseData from "@/views/Project/BaseData.vue"
import { mapState } from "vuex"

export default {
	name: "projectLayout",
	components: {
		ProjectBaseData
	},
	data() {
		return {
			top: 70,
			isHasProjectId: true
		}
	},
	computed: {
		...mapState("project", ["infoHeight"])
	},
	watch: {
		/*
		 * 此处作用：
		 * 当在项目内切换其他项目的时候，
		 * 由于当前页面已经create之后了
		 * 如果要刷新数据，就需要项目内的页面,
		 * 每个页面都要加一个watch方法，
		 * 监听projectId的变化，为了减少代码的修改
		 * 此处将路由删除再增加
		 * 缺点：每次切换项目，会默认跳转到项目预览的页面
		 * 无法停留在当前切换的页面
		 * */
		$route(val, oldVal) {
			if (val.params.projectId !== oldVal.params.projectId) {
				this.isHasProjectId = false
				this.$nextTick(() => {
					this.isHasProjectId = true
				})
			}
		}
	},
	methods: {
		onChange() {
			this.$store.commit("project/updateState", {
				infoHeight: this.infoHeight === 50 ? 158 : 50
			})
		}
	}
}
</script>

<style scoped>
.slide-fade {
	position: absolute;
	left: 0;
	right: 0;
}

.slide-fade-enter-active {
	transition: all 0.8s ease;
}

.slide-fade-leave-active {
	transition: all 0.1s cubic-bezier(2, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
	left: 0;
	right: 0;
	transform: translateX(50px);
	opacity: 0;
}
.project-content {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto;
	overflow-x: hidden;
	/*padding-top: 16px;*/
	transition: top 0.3s ease;
}
</style>
