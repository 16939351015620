import { systemRouterMap, projectRouterMap } from "@/config/router.config"

const systemPath = {}
const projectPath = {}

function loop(data, result) {
	if (data && data.length) {
		data.forEach(item => {
			Object.assign(result, {
				// 由于有params参数，因此面包屑需要将projectId过滤掉
				[item.path.replace("/:projectId", "")]: item.meta.title
			})
			loop(item.children, result)
		})
	}
}
loop(systemRouterMap, systemPath)
loop(projectRouterMap, projectPath)
const breadcrumbNameObj = {
	...systemPath,
	...projectPath
}

export default breadcrumbNameObj
