<template>
	<div class="all-router-wrap" style="height: 100%">
		<keep-alive>
			<transition name="slide-fade" mode="out-in">
				<router-view v-if="$route.meta.keepAlive" />
			</transition>
		</keep-alive>
		<transition name="slide-fade" mode="out-in">
			<router-view v-if="!$route.meta.keepAlive"></router-view>
		</transition>
	</div>
</template>

<script>
export default {
	name: "MainLayout"
}
</script>

<style scoped>
.slide-fade {
	position: absolute;
	left: 0;
	right: 0;
}
.slide-fade-enter-active {
	transition: all 0.8s ease;
}
.slide-fade-leave-active {
	transition: all 0.1s cubic-bezier(2, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
	left: 0;
	right: 0;
	transform: translateX(50px);
	opacity: 0;
}
</style>
