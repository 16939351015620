<template>
	<Modal
		:value="visible"
		width="80%"
		:fullscreen="fullscreen"
		:styles="{ top: fullscreen ? 0 : '20px' }"
		:mask-closable="false"
		@on-cancel="onCancel"
		class="help-modal"
	>
		<Spin fix v-if="loading"></Spin>
		<div slot="header" class="help-modal-header">
			<h2>{{ title }}</h2>
			<a href="javascript:;" class="fullscreen" @click="handleFullScreen">
				<Tooltip :content="fullscreen ? '退出全屏' : '全屏查看'">
					<i v-if="!fullscreen" class="iconfont icon-fullscreen-fill"></i>
					<i v-else class="iconfont icon-fullscreen-exit-fill"></i>
				</Tooltip>
			</a>
		</div>
		<div v-if="visible && isShow">
			<div :style="`height:${conHeight}px`" v-if="entry === 'login'">
				<Carousel v-model="helpVal" loop arrow="always">
					<CarouselItem v-for="(img, index) in imgList" :key="index">
						<img :style="`height:${conHeight}px`" :src="img" />
					</CarouselItem>
				</Carousel>
			</div>
			<Tabs v-model="tabName" v-else @on-click="handleClickTab">
				<TabPane
					:label="item.label"
					:name="item.name"
					v-for="item in tabList"
					:key="item.name"
				>
					<div :style="`height:${conHeight}px`">
						<Carousel v-model="item.helpVal" loop arrow="always">
							<CarouselItem v-for="(img, index) in item.imgList" :key="index">
								<img :style="`height:${conHeight}px`" :src="img" />
							</CarouselItem>
						</Carousel>
					</div>
				</TabPane>
			</Tabs>
		</div>
		<div slot="footer">
			<Button @click="onCancel">关闭</Button>
		</div>
	</Modal>
</template>

<script>
import config from "@/config/helpImg"

const { projectHelpList, processList } = config
export default {
	name: "help",
	props: ["title", "visible", "imgList", "entry"],
	data() {
		return {
			helpVal: 0,
			fullscreen: false, // 是否全屏弹出框
			tabList: [
				{
					label: "新增项目",
					name: "project",
					imgList: [],
					helpVal: 0
				},
				{
					label: "流程设置",
					name: "process",
					imgList: [],
					helpVal: 0
				}
			],
			tabName: "project",
			projectHelpList,
			processList,
			isShow: true,
			loading: false
		}
	},
	computed: {
		conHeight() {
			const height = this.fullscreen
				? document.body.clientHeight - 145
				: document.body.clientHeight - 185
			return this.entry === "login" ? height : height - 100
		}
	},
	watch: {
		visible(val) {
			if (val) {
				this.loading = true
				this.tabList[0].imgList = this.projectHelpList
				this.tabList[1].imgList = this.processList
				this.loading = false
			}
		}
	},
	methods: {
		handleFullScreen() {
			this.fullscreen = !this.fullscreen
			this.loading = true
			this.isShow = false
			this.$nextTick(() => {
				setTimeout(() => {
					this.isShow = true
					this.loading = false
				}, 200)
			})
		},
		handleClickTab(name) {
			// console.log('click tab', name);
		},
		onCancel() {
			this.$emit("close")
			this.fullscreen = false
		}
	}
}
</script>
<style lang="less">
.help-modal {
	position: relative;
	.ivu-modal {
		top: 20px;
		.ivu-carousel-item {
			img {
				width: 100%;
			}
		}
		.ivu-carousel-arrow {
			width: 56px;
			height: 56px;
			background-color: #2d8cf0;
			.ivu-icon {
				font-size: 28px;
			}
		}
		.help-modal-header {
			overflow: hidden;
			h2 {
				float: left;
			}
			.fullscreen {
				float: right;
				margin-right: 40px;
				text-decoration: none;
			}
			.iconfont {
				font-size: 20px;
			}
		}
	}
	/*img {*/
	/*width: 100%;*/
	/*height: 100%;*/
	/*}*/
}
</style>
