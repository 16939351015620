<template>
	<div class="right-nav-wrap">
		<!--    <a href="javascript:;"-->
		<!--       class="help"-->
		<!--       @click="handleHelp"-->
		<!--       v-if="isXinhua">-->
		<!--      <Icon type="md-help-circle"-->
		<!--            style="margin-right:6px;font-size: 22px;" />帮助中心-->
		<!--    </a>-->
		<Poptip v-model="notifyVisible">
			<span class="custom-notify">
				<Badge :count="notifyList.length" type="primary">
					<CtmsIcon type="icon-Mail" :size="28"></CtmsIcon>
					<!--<Icon type="md-mail" size="30"/>-->
				</Badge>
				<span
					style="
						margin-left: 15px;
						padding: 0 5px;
						color: #fff;
						cursor: default;
					"
					>|</span
				>
			</span>
			<div slot="content">
				<ul class="notify-list-wrapper" v-if="notifyList.length">
					<li
						v-for="(item, index) in notifyList"
						:key="index"
						@click="toApprove(item)"
					>
						<Tooltip
							v-if="item.messageContent"
							:max-width="300"
							placement="bottom-start"
							:content="item.messageContent"
						>
							<p v-html="item.messageTitle"></p>
						</Tooltip>
						<p
							v-else
							:title="item.messageContent"
							v-html="item.messageTitle"
						></p>
					</li>
				</ul>
				<div v-else class="no-notify">暂无新消息</div>
				<!--<p class="more-notify">查看全部通知</p>-->
			</div>
		</Poptip>
		<Dropdown @on-click="handleClick" class="global-header__user">
			<span class="user-name">{{ name }}</span>
			<Avatar v-if="!!avatar" class="user-img" :src="avatar" />
			<Avatar icon="md-person" v-else />
			<DropdownMenu slot="list">
				<!--<DropdownItem name="message">
            消息中心<Badge style="margin-left: 10px" :count="messageUnreadCount"></Badge>
        </DropdownItem>-->
				<DropdownItem
					name="personalIndex"
					v-if="
						systemMenuPermissions &&
						systemMenuPermissions.indexOf('menu_personal_info') > -1
					"
				>
					<Icon type="md-person" />
					基本信息
				</DropdownItem>
				<DropdownItem
					name="personalInfo"
					v-if="
						systemMenuPermissions &&
						systemMenuPermissions.indexOf('menu_personal_cv') > -1
					"
				>
					<Icon type="ios-contact" />
					个人简历
				</DropdownItem>
				<DropdownItem
					name="personalSignature"
					v-if="
						systemMenuPermissions &&
						systemMenuPermissions.indexOf('menu_personal_sign') > -1
					"
				>
					<Icon type="ios-create" />
					个人签名
				</DropdownItem>
				<DropdownItem name="logout">
					<Icon type="md-log-out" />
					退出登录
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
		<Help :visible="helpVisible" :title="helpTitle" @close="closeHelp"></Help>
		<Approval
			:visible="approvalVisible"
			:approveNo="approveNo"
			:projectId="projectId"
			@onOk="handleOk"
			@onCancel="handleCancel"
			:procInstId="procInstId"
			type="toDo"
			:toDoId="toDoId"
		></Approval>
	</div>
</template>

<script>
import { mapState } from "vuex"
import defaultAvatar from "@/assets/common/defaultAvatar.png"
import Help from "@/components/BusinessComponents/Help/Help.vue"
import Approval from "@approval"
import api from "@/api/user/notify"

const { getMessages, getMessageInfo, getExtraInfo } = api

export default {
	name: "HeaderUser",
	components: {
		Help,
		Approval
	},
	data() {
		return {
			helpTitle: "帮助中心",
			defaultAvatar,
			helpVisible: false,
			notifyVisible: false,
			approvalVisible: false,
			toDoId: "",
			procInstId: "",
			projectId: "",
			approveNo: "",
			notifyList: [],
			timer: null
		}
	},
	computed: {
		...mapState("user", {
			avatar: state => (state.avatar === "undefined" ? "" : state.avatar),
			name: state => state.name
		}),
		...mapState("permission", ["systemMenuPermissions"]),
		isXinhua() {
			return this.$store.state.environment === "xinhua"
		}
	},
	created() {
		this.initMessages()
		this.timer = setInterval(
			() => {
				this.initMessages()
			},
			5 * 60 * 1000
		)
	},
	methods: {
		handleHelp() {
			// 注册帮助
			this.helpVisible = true
		},
		closeHelp() {
			// 关闭帮助
			this.helpVisible = false
		},
		async getMsgInfo(messageId) {
			const res = await getMessageInfo({ messageId })
			if (res) {
				this.initMessages()
			}
		},
		handleClick(name) {
			switch (name) {
				case "logout":
					clearInterval(this.timer)
					this.timer = null
					this.$store.dispatch("user/logout")
					break
				case "personalIndex":
					this.$router.push({ name: "personalIndex" })
					break
				case "personalInfo":
					this.$router.push({ name: "personalInfo" })
					break
				case "personalSignature":
					this.$router.push({ name: "personalSignature" })
					break
				case "message":
					this.message()
					break
				default:
					break
			}
		},
		async initMessages() {
			const res = await getMessages()
			if (res) {
				this.notifyList = res.data || []
			}
		},
		// 审批提交成功
		handleOk() {
			this.approvalVisible = false
			this.page.current = 1
			this.$emit("onOk")
			this.$nextTick(() => {
				this.initMessages()
			})
		},
		// 审批关闭
		handleCancel() {
			this.procInstId = ""
			this.toDoId = ""
			this.approvalVisible = false
		},
		// 去 审批 / 跳转等操作
		async toApprove(row) {
			this.getMsgInfo(row.id)
			// 待审批的流程
			if (row.messageType === "1") {
				const messageUrlParams = JSON.parse(row.messageUrlParams)
				this.toDoId = messageUrlParams.todoId
				const res = await getExtraInfo({ id: this.toDoId })
				if (res) {
					this.procInstId = messageUrlParams.procInstId
					this.projectId = messageUrlParams.projectId
					this.approvalVisible = true
				}
			}
			if (row.messageType === "2") {
				// 伦理批件到期提醒 跳转到项目工作台
				this.$router.push({ path: `/project/${row.projectId}/workbench` })
			}
			if (row.messageType === "7") {
				// 经费管理
				const messageUrlParams = JSON.parse(row.messageUrlParams)
				if (messageUrlParams.classification === 1) {
					this.$router.push({
						path: `/project/${messageUrlParams.projectId}/funds/org`
					})
				}
				if (messageUrlParams.classification === 2) {
					this.$router.push({
						path: `/project/${messageUrlParams.projectId}/funds/ethical`
					})
				}
			}
			if (row.messageType === "9") {
				// 合同管理跳转
				this.$router.push({ path: `/project/${row.projectId}/conManage` })
			}
			if (row.messageType === "10") {
				// 伦理上会跳转
				this.$router.push({ path: "/special/list" })
			}
		}
	}
}
</script>

<style lang="less">
.right-nav-wrap {
	cursor: pointer;
	float: right;
	height: 64px;
	.help {
		color: #fff;
		font-size: 16px;
		margin-right: 10px;
		text-decoration: none;
	}
	.no-notify {
		text-align: center;
	}
	.ivu-poptip-body {
		max-height: 300px;
		overflow: auto;
	}
	.custom-notify {
		display: inline-block;
		vertical-align: bottom;
		text-align: center;
		.ivu-icon {
			color: #ffffff;
		}
		.ivu-badge {
			line-height: 1;
		}
		.ivu-badge-count {
			top: -5px;
			right: 2px;
			line-height: 1.2;
			height: 18px;
			min-width: inherit;
			padding: 0 4px;
		}
	}
	.notify-list-wrapper {
		width: 280px;
		li {
			white-space: normal;
			margin-bottom: 8px;
		}
		li:last-child {
			margin-bottom: 0;
		}
	}
	.more-notify {
		text-align: right;
		padding-top: 8px;
		color: #2d8cf0;
		box-shadow: 0px 0px 2px #ddd;
	}
}
.global-header__user {
	.user-name {
		padding-left: 15px;
		padding-right: 5px;
		color: #fff;
		font-size: 16px;
		margin-right: 10px;
	}
	.ivu-avatar {
		background: #f2f2f2;
		border-radius: 8px;
	}
	.ivu-avatar.ivu-avatar-icon {
		font-size: 24px;
		color: #cbcbcb;
	}
	.ivu-icon.ivu-icon-md-arrow-dropdown {
		color: #fff;
	}
	.ivu-select-dropdown {
		z-index: 999;
	}
	.ivu-avatar > img {
		margin-top: -3px;
	}
	.ivu-dropdown-item {
		line-height: 30px;
		padding: 5px 40px 5px 15px;
		.ivu-icon {
			font-size: 18px;
			margin-right: 8px;
		}
	}
}
</style>
