<template>
	<Breadcrumb class="header-breadcrumb">
		<BreadcrumbItem
			:key="item"
			v-for="(item, index) in pathSnippets.slice(0, pathSnippets.length - 1)"
			:to="
				getBreadcrumbLinkPath('/' + pathSnippets.slice(0, index + 1).join('/'))
			"
		>
			{{ breadcrumbNameObj["/" + pathSnippets.slice(0, index + 1).join("/")] }}
		</BreadcrumbItem>
		<BreadcrumbItem>{{
			breadcrumbNameObj["/" + pathSnippets.join("/")]
		}}</BreadcrumbItem>
	</Breadcrumb>
</template>

<script>
import breadcrumbNameObj from "@/config/breadcrumb.config"

export default {
	name: "HeaderBreadcrumb",
	data() {
		return {
			breadcrumbNameObj
		}
	},
	methods: {
		getBreadcrumbLinkPath(path) {
			if (path.indexOf("/project/") > -1) {
				return path.replace(
					"/project",
					`/project/${this.$route.params.projectId}`
				)
			}
			return path
		}
	},
	computed: {
		pathSnippets() {
			// 判断是否进入项目之后
			if (this.$route.params.projectId) {
				// 将projectId那一层去掉
				return this.$route.path
					.replace(`/${this.$route.params.projectId}`, "")
					.split("/")
					.filter(item => !!item)
			}
			return this.$route.path.split("/").filter(item => !!item)
		}
	}
}
</script>

<style lang="less">
.header-breadcrumb {
	float: left;
	padding-left: 40px;
	border-left: 1px solid #fff;
	height: 34px;
	margin-top: 15px;
	line-height: 34px;
	&.ivu-breadcrumb {
		font-size: 18px;
		a {
			color: #fff;
		}
		> span {
			color: #2d8cf0;
			font-weight: normal;
		}
	}
}
</style>
