var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"global-slide-menu"},[_c('Menu',{ref:"slideMenu",attrs:{"width":"200px","active-name":_vm.currentRouterName,"open-names":[
			'Project',
			'Achievement',
			'Special',
			'Report',
			'Resource',
			'Account',
			'Personal',
			'setting',
			'Log'
		]},on:{"on-select":_vm.selectMenu}},[(_vm.isInProject)?_c('MenuItem',{staticClass:"first-menu-item",attrs:{"name":"project"}},[_c('p',{staticClass:"first-title"},[_c('Icon',{attrs:{"type":"ios-undo","size":18}}),_vm._v("返回项目列表\n\t\t\t")],1)]):_vm._e(),_vm._l((_vm.routerMap),function(submenu){return [(submenu.meta.mode === 'single')?_c('MenuItem',{key:submenu.name,staticClass:"first-menu-item",class:_vm.activeClass(submenu),attrs:{"name":submenu.name,"replace":_vm.currentPath === submenu.path}},[_c('p',{staticClass:"first-title"},[_c('CtmsIcon',{attrs:{"type":_vm.currentRouterName.indexOf(submenu.name) > -1
								? submenu.meta.activeIcon
								: submenu.meta.icon,"size":18}}),_vm._v(_vm._s(submenu.meta.title)+"\n\t\t\t\t")],1)]):(!submenu.children)?_c('MenuItem',{key:submenu.name,staticClass:"first-menu-item",class:_vm.activeClass(submenu),attrs:{"name":submenu.name,"replace":_vm.currentPath === submenu.path}},[_c('p',{staticClass:"first-title"},[_c('CtmsIcon',{attrs:{"type":_vm.currentRouterName.indexOf(submenu.name) > -1
								? submenu.meta.activeIcon
								: submenu.meta.icon,"size":18}}),_vm._v(_vm._s(submenu.meta.title)+"\n\t\t\t\t")],1)]):_c('Submenu',{key:submenu.name,staticClass:"first-menu-item",class:_vm.activeChildClass(submenu),attrs:{"name":submenu.name}},[_c('template',{slot:"title"},[_c('p',{staticClass:"sub-menu-title"},[_c('CtmsIcon',{attrs:{"type":_vm.currentRouterName.indexOf(submenu.name) > -1
									? submenu.meta.activeIcon
									: submenu.meta.icon,"size":18}}),_vm._v(_vm._s(submenu.meta.title)+"\n\t\t\t\t\t")],1)]),(submenu.children)?[_vm._l((submenu.children),function(menuItem){return [(menuItem.meta.mode === 'single')?_c('MenuItem',{key:menuItem.name,staticClass:"sub-menu-item",class:_vm.activeClass(menuItem),staticStyle:{"padding-left":"56px"},attrs:{"name":menuItem.name,"replace":_vm.currentPath === menuItem.path}},[_c('p',{staticClass:"sub-title"},[_vm._v(_vm._s(menuItem.meta.title))])]):_c('MenuItem',{key:menuItem.name,staticClass:"sub-menu-item",class:_vm.activeClass(menuItem),staticStyle:{"padding-left":"56px"},attrs:{"name":menuItem.name,"replace":_vm.currentPath === submenu.path}},[_c('p',{staticClass:"sub-title"},[_vm._v(_vm._s(menuItem.meta.title))])])]})]:_vm._e()],2)]})],2)],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }