<template>
	<Header class="global-layout__header">
		<SlideLogo></SlideLogo>
		<HeaderBreadcrumb></HeaderBreadcrumb>
		<!--<ProjectListSwitch></ProjectListSwitch>-->
		<HeaderUser></HeaderUser>
	</Header>
</template>

<script>
import HeaderBreadcrumb from "./HeaderBreadcrumb.vue"
import HeaderUser from "./HeaderUser.vue"
import SlideLogo from "./SlideLogo.vue"
// import ProjectListSwitch from './ProjectListSwitch.vue';

export default {
	name: "index",
	components: {
		HeaderBreadcrumb,
		HeaderUser,
		SlideLogo
		// ProjectListSwitch,
	},
	computed: {
		isXinhua() {
			return this.$store.state.environment === "xinhua"
		}
	},
	created() {
		// 获取所有枚举
		// this.$store.dispatch('getE/numerate');
		// 获取系统配置
		// this.$store.dispatch('config/getSystemConfig');
	}
}
</script>

<style scoped lang="less">
.global-layout__header {
	background: #1c2730;
	//background: linear-gradient(to right, #fe834b, #fda21e);
	padding: 0 40px;
	z-index: 3;
	position: relative;
	&.xinhua {
		padding: 0 40px 0 20px;
	}
}
</style>
