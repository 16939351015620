<template>
	<!--跟踪审查-->
	<Modal
		class="follow-check-modal"
		:value="visible"
		title="跟踪审查"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form
			v-if="visible"
			:model="formData"
			ref="followCheckForm"
			:rules="rules"
			label-position="top"
			class="follow-check-form"
		>
			<FormItem label="伦理批件签发日期：" prop="componentValue">
				<p style="margin-left: 10px">{{ formData.componentValue || "--" }}</p>
			</FormItem>
			<FormItem label="伦理批件有效期：" prop="notifyType">
				<RadioGroup
					v-model="formData.notifyType"
					@on-change="handleChangeCheck"
				>
					<!--100-其他，1-3个月，2-6个月，3-12个月-->
					<Radio :label="1">3个月</Radio>
					<Radio :label="2">6个月</Radio>
					<Radio :label="3">12个月</Radio>
					<Radio :label="100">其他</Radio>
				</RadioGroup>
				<template v-if="formData.notifyType === 100">
					<InputNumber
						:min="2"
						:max="36"
						:precision="0"
						:active-change="false"
						size="small"
						@on-change="handleChangeOther"
						v-model="formData.notifyFrequency"
					></InputNumber>
					<span style="vertical-align: middle"> 个月</span>
				</template>
			</FormItem>
			<FormItem label="短信提醒日期：" prop="messageDate">
				<p :style="`color: ${color}`">{{ formData.messageDate || "--" }}</p>
			</FormItem>
			<p style="color: #a5a5a5; font-size: 12px">{{ tip }}</p>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" @click="onOk" :loading="loading">提交</Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/project/project"
import { mapState } from "vuex"
import moment from "moment"

const { apiSetFollowCheck } = api

export default {
	name: "followCheck",
	props: ["visible"],
	data() {
		return {
			loading: false,
			tip: "提醒日早10:00会以短信形式通知项目内申办方角色",
			color: "#666",
			formData: {
				componentValue: "",
				notifyType: null,
				messageDate: "",
				notifyFrequency: 2
			},
			rules: {
				componentValue: {
					required: true,
					message: "伦理批件签发日期不能为空",
					trigger: "blur"
				},
				// messageDate: {
				//   required: true, message: '短信提醒日期不能为空', trigger: 'blur',
				// },
				notifyType: {
					required: true,
					type: "number",
					message: "伦理批件有效期不能为空",
					trigger: "change"
				}
			},
			nowDate: ""
		}
	},
	computed: {
		...mapState("project", ["followCheckData"])
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$store
					.dispatch("project/getFollowCheck", this.$route.params.projectId)
					.then(() => {
						this.$nextTick(() => {
							this.formData = {
								...this.followCheckData,
								notifyFrequency:
									this.followCheckData.notifyType === 100 &&
									!this.followCheckData.notifyFrequency
										? 2
										: this.followCheckData.notifyFrequency
							}
							this.$refs.followCheckForm &&
								this.$refs.followCheckForm.resetFields()
							if (this.formData.notifyType && this.formData.componentValue) {
								this.getMessageTime(
									this.formData.componentValue,
									this.formData.notifyType
								)
							}
						})
					}) // 获取跟踪审查信息
			}
		}
	},
	methods: {
		handleChangeCheck(val) {
			this.formData.notifyType = val
			if (val === 100) {
				this.formData.notifyFrequency = this.formData.notifyFrequency || 2
			}
			if (val && this.formData.componentValue) {
				this.getMessageTime(this.formData.componentValue, val)
			} else {
				this.formData.messageDate = ""
			}
		},
		// 更改其他月份
		handleChangeOther(val) {
			if (val && this.formData.componentValue) {
				this.getMessageTime(this.formData.componentValue, 100)
			} else {
				this.formData.messageDate = ""
			}
		},
		// 获取短信通知日期
		getMessageTime(checkTime, month) {
			let date = ""
			let newMonth = month
			if (month === 100) {
				// 其他 月份
				newMonth = this.formData.notifyFrequency || 0
			} else if (month === 1) {
				// 3个月/次
				newMonth = 3
			} else if (month === 2) {
				// 6个月/次
				newMonth = 6
			} else if (month === 3) {
				// 1年/次
				newMonth = 12
			}
			if (checkTime && newMonth) {
				let arr = checkTime.split("-")
				// 月份是0-11，需要减1来计算。
				arr = arr.map(item => Number(item.trim()))
				if (arr.length === 3 && arr[2] !== 0) {
					const arrDate = new Date(arr.join("-"))
					// 短信通知日期 需要提前一个月通知，要减掉1；
					const timeD = moment(arrDate)
						.add(newMonth - 1, "months")
						.format("YYYY-MM-DD")
					const timeE = moment(arrDate)
						.add(newMonth - 1, "months")
						.format("YYYY-MM-DD 10:00:00")
					const messageTime = new Date(timeE).getTime() // 短信通知日期 时间戳
					// 伦理批件有效期
					const timeEthics = moment(arrDate)
						.add(newMonth, "months")
						.format("YYYY-MM-DD 23:59:59")
					const EthicsTime = new Date(timeEthics).getTime() // 伦理批件有效期 时间戳
					// 当天日期
					this.nowDate = new Date().getTime()
					if (messageTime >= this.nowDate) {
						// 如果发送信息的日期在今天之后，则提示 发送日期
						date = timeD
						this.tip = "提醒日早10:00会以短信形式通知项目内申办方角色"
						this.color = "#666"
					} else {
						// 如果发送信息的日期在今天之前
						if (EthicsTime >= this.nowDate) {
							// 如果发送信息的日期在今天之前，且在伦理批件有效期以内，提示今天
							date = "今天"
						} else {
							// 如果发送信息的日期在今天之前，且在伦理批件有效期以外，提示过期
							date = "伦理批件已过期"
						}
						this.tip = "提交后即刻以短信形式通知项目内申办方角色"
						this.color = "#ff5722"
					}
				}
			} else {
				date = ""
			}
			this.$nextTick(() => {
				this.$set(this.formData, "messageDate", date)
			})
		},
		// 提交
		onOk() {
			this.$refs.followCheckForm.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						const res = await apiSetFollowCheck({
							projectId: this.$route.params.projectId,
							componentAlias: "ethic_approval_date",
							...this.formData,
							notifyFrequency:
								this.formData.notifyType === 100
									? this.formData.notifyFrequency
									: undefined
						})
						if (res) {
							this.$Message.success("操作成功！")
							this.$emit("onOk")
						}
						this.loading = false
					})
				}
			})
		},
		// 关闭
		onCancel() {
			this.$emit("onCancel")
		}
	}
}
</script>

<style lang="less">
.follow-check-modal {
	.follow-check-form {
		padding: 10px 20px 0;
	}
	.reason-input {
		width: 500px;
		textarea.ivu-input {
			font-size: 14px;
		}
	}
}
</style>
