import step1 from "../assets/images/xinhua/1.jpg"
import step2 from "../assets/images/xinhua/2.png"
import step3 from "../assets/images/xinhua/3.png"
import step4 from "../assets/images/xinhua/4.png"
import step5 from "../assets/images/xinhua/5.png"
import step6 from "../assets/images/xinhua/6.png"
import step7 from "../assets/images/xinhua/7.png"
import step8 from "../assets/images/xinhua/8.png"
import step9 from "../assets/images/xinhua/9.png"
import step10 from "../assets/images/xinhua/10.png"
import step11 from "../assets/images/xinhua/11.png"
import step12 from "../assets/images/xinhua/12.png"
import step13 from "../assets/images/xinhua/13.png"
import step14 from "../assets/images/xinhua/14.png"
import step15 from "../assets/images/xinhua/15.png"
import process1 from "../assets/images/xinhua/process/01.jpg"
import process2 from "../assets/images/xinhua/process/02.jpg"
import process3 from "../assets/images/xinhua/process/03.jpg"
import process4 from "../assets/images/xinhua/process/04.jpg"
import process5 from "../assets/images/xinhua/process/05.jpg"
import process6 from "../assets/images/xinhua/process/06.jpg"
import process7 from "../assets/images/xinhua/process/07.jpg"

// 新建项目 向导页
const projectHelpList = [
	step1,
	step2,
	step3,
	step4,
	step5,
	step6,
	step7,
	step8,
	step9,
	step10,
	step11,
	step12,
	step13,
	step14,
	step15
]
// 流程设置 向导页
const processList = [
	process1,
	process2,
	process3,
	process4,
	process5,
	process6,
	process7
]

export default {
	projectHelpList,
	processList
}
