<template>
	<!--编号修改-->
	<Modal
		class="code-form-modal"
		:value="visible"
		title="编号修改"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form
			v-if="visible"
			:model="formData"
			ref="codeForm"
			:rules="rules"
			label-position="top"
			class="code-form"
		>
			<FormItem label="机构立项编号：" prop="orgCode">
				<Input
					placeholder="请输入机构立项编号"
					v-model.trim="formData.orgCode"
					:maxlength="100"
				/>
			</FormItem>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" @click="onOk" :loading="loading">提交</Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/project/project"

const { apiUpdateCode } = api

export default {
	name: "codeEdit",
	props: ["visible", "orgCode"],
	data() {
		return {
			loading: false,
			formData: {
				orgCode: ""
			},
			rules: {
				orgCode: {
					required: true,
					message: "机构立项编号不能为空",
					trigger: "blur"
				}
			}
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.formData = {
					orgCode: this.orgCode
				}
				this.$refs.codeForm && this.$refs.codeForm.resetFields()
			}
		}
	},
	methods: {
		// 提交
		onOk() {
			this.$refs.codeForm.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						const res = await apiUpdateCode({
							projectId: this.$route.params.projectId,
							mechanismProjectNo: this.formData.orgCode
						})
						if (res) {
							this.$Message.success("操作成功！")
							this.$emit("onOk")
						}
						this.loading = false
					})
				}
			})
		},
		// 关闭
		onCancel() {
			this.$emit("onCancel")
		}
	}
}
</script>

<style lang="less">
.code-form-modal {
	.code-form {
		padding: 10px 20px 0;
	}
	.reason-input {
		width: 500px;
		textarea.ivu-input {
			font-size: 14px;
		}
	}
}
</style>
