var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right-nav-wrap"},[_c('Poptip',{model:{value:(_vm.notifyVisible),callback:function ($$v) {_vm.notifyVisible=$$v},expression:"notifyVisible"}},[_c('span',{staticClass:"custom-notify"},[_c('Badge',{attrs:{"count":_vm.notifyList.length,"type":"primary"}},[_c('CtmsIcon',{attrs:{"type":"icon-Mail","size":28}})],1),_c('span',{staticStyle:{"margin-left":"15px","padding":"0 5px","color":"#fff","cursor":"default"}},[_vm._v("|")])],1),_c('div',{attrs:{"slot":"content"},slot:"content"},[(_vm.notifyList.length)?_c('ul',{staticClass:"notify-list-wrapper"},_vm._l((_vm.notifyList),function(item,index){return _c('li',{key:index,on:{"click":function($event){return _vm.toApprove(item)}}},[(item.messageContent)?_c('Tooltip',{attrs:{"max-width":300,"placement":"bottom-start","content":item.messageContent}},[_c('p',{domProps:{"innerHTML":_vm._s(item.messageTitle)}})]):_c('p',{attrs:{"title":item.messageContent},domProps:{"innerHTML":_vm._s(item.messageTitle)}})],1)}),0):_c('div',{staticClass:"no-notify"},[_vm._v("暂无新消息")])])]),_c('Dropdown',{staticClass:"global-header__user",on:{"on-click":_vm.handleClick}},[_c('span',{staticClass:"user-name"},[_vm._v(_vm._s(_vm.name))]),(!!_vm.avatar)?_c('Avatar',{staticClass:"user-img",attrs:{"src":_vm.avatar}}):_c('Avatar',{attrs:{"icon":"md-person"}}),_c('DropdownMenu',{attrs:{"slot":"list"},slot:"list"},[(
						_vm.systemMenuPermissions &&
						_vm.systemMenuPermissions.indexOf('menu_personal_info') > -1
					)?_c('DropdownItem',{attrs:{"name":"personalIndex"}},[_c('Icon',{attrs:{"type":"md-person"}}),_vm._v("\n\t\t\t\t\t基本信息\n\t\t\t\t")],1):_vm._e(),(
						_vm.systemMenuPermissions &&
						_vm.systemMenuPermissions.indexOf('menu_personal_cv') > -1
					)?_c('DropdownItem',{attrs:{"name":"personalInfo"}},[_c('Icon',{attrs:{"type":"ios-contact"}}),_vm._v("\n\t\t\t\t\t个人简历\n\t\t\t\t")],1):_vm._e(),(
						_vm.systemMenuPermissions &&
						_vm.systemMenuPermissions.indexOf('menu_personal_sign') > -1
					)?_c('DropdownItem',{attrs:{"name":"personalSignature"}},[_c('Icon',{attrs:{"type":"ios-create"}}),_vm._v("\n\t\t\t\t\t个人签名\n\t\t\t\t")],1):_vm._e(),_c('DropdownItem',{attrs:{"name":"logout"}},[_c('Icon',{attrs:{"type":"md-log-out"}}),_vm._v("\n\t\t\t\t\t退出登录\n\t\t\t\t")],1)],1)],1),_c('Help',{attrs:{"visible":_vm.helpVisible,"title":_vm.helpTitle},on:{"close":_vm.closeHelp}}),_c('Approval',{attrs:{"visible":_vm.approvalVisible,"approveNo":_vm.approveNo,"projectId":_vm.projectId,"procInstId":_vm.procInstId,"type":"toDo","toDoId":_vm.toDoId},on:{"onOk":_vm.handleOk,"onCancel":_vm.handleCancel}})],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }