<template>
	<div class="global-slide-menu">
		<Menu
			width="200px"
			ref="slideMenu"
			:active-name="currentRouterName"
			:open-names="[
				'Project',
				'Achievement',
				'Special',
				'Report',
				'Resource',
				'Account',
				'Personal',
				'setting',
				'Log'
			]"
			@on-select="selectMenu"
		>
			<MenuItem name="project" class="first-menu-item" v-if="isInProject">
				<p class="first-title">
					<Icon type="ios-undo" :size="18" />返回项目列表
				</p>
			</MenuItem>
			<template v-for="submenu in routerMap">
				<!--药房管理，使用嵌套路由展现平行路由效果，需要特殊处理-->
				<MenuItem
					:key="submenu.name"
					class="first-menu-item"
					v-if="submenu.meta.mode === 'single'"
					:name="submenu.name"
					:class="activeClass(submenu)"
					:replace="currentPath === submenu.path"
				>
					<p class="first-title">
						<CtmsIcon
							:type="
								currentRouterName.indexOf(submenu.name) > -1
									? submenu.meta.activeIcon
									: submenu.meta.icon
							"
							:size="18"
						/>{{ submenu.meta.title }}
					</p>
				</MenuItem>
				<!--没有子路由-->
				<MenuItem
					:key="submenu.name"
					class="first-menu-item"
					v-else-if="!submenu.children"
					:name="submenu.name"
					:class="activeClass(submenu)"
					:replace="currentPath === submenu.path"
				>
					<p class="first-title">
						<CtmsIcon
							:type="
								currentRouterName.indexOf(submenu.name) > -1
									? submenu.meta.activeIcon
									: submenu.meta.icon
							"
							:size="18"
						/>{{ submenu.meta.title }}
					</p>
				</MenuItem>
				<!--有子路由-->
				<Submenu
					v-else
					class="first-menu-item"
					:class="activeChildClass(submenu)"
					:key="submenu.name"
					:name="submenu.name"
				>
					<template slot="title">
						<p class="sub-menu-title">
							<CtmsIcon
								:type="
									currentRouterName.indexOf(submenu.name) > -1
										? submenu.meta.activeIcon
										: submenu.meta.icon
								"
								:size="18"
							/>{{ submenu.meta.title }}
						</p>
					</template>
					<template v-if="submenu.children">
						<template v-for="menuItem in submenu.children">
							<MenuItem
								:key="menuItem.name"
								class="sub-menu-item"
								style="padding-left: 56px"
								v-if="menuItem.meta.mode === 'single'"
								:name="menuItem.name"
								:class="activeClass(menuItem)"
								:replace="currentPath === menuItem.path"
							>
								<p class="sub-title">{{ menuItem.meta.title }}</p>
							</MenuItem>
							<MenuItem
								:key="menuItem.name"
								class="sub-menu-item"
								style="padding-left: 56px"
								v-else
								:class="activeClass(menuItem)"
								:name="menuItem.name"
								:replace="currentPath === submenu.path"
							>
								<p class="sub-title">{{ menuItem.meta.title }}</p>
							</MenuItem>
						</template>
					</template>
				</Submenu>
			</template>
		</Menu>
	</div>
</template>

<script>
import "./menu.less"
import { mapState } from "vuex"

export default {
	name: "SlideMenu",
	data() {
		return {
			routerMap: [],
			isInProject: false,
			openNames: []
		}
	},
	created() {
		// 获取菜单
		this.setRouterMap()
	},
	mounted() {
		// const systemRouter = this.systemRouter;
		// systemRouter.forEach((item) => {
		//   if (item.children && item.children.length > 0) {
		//     this.openNames.push(item.name);
		//   }
		// });
	},
	computed: {
		...mapState("permission", ["systemRouter", "projectRouter"]),
		...mapState("project", ["projectInfo"]),
		currentPath() {
			return this.$route.path || "/"
		},
		currentRouterName() {
			return this.$route.name || ""
		},
		env() {
			return this.$store.state.environment
		}
	},
	watch: {
		$route() {
			this.setRouterMap()
		}
	},
	methods: {
		// 根绝path判断是否进入项目菜单，渲染不同的menu
		setRouterMap() {
			if (this.$route.path.indexOf("/project/") > -1) {
				// 项目菜单
				// 判断是否显示返回首页的按钮
				if (!this.isInProject) {
					this.isInProject = true
				}
				const arr = this.replaceProjectId(this.projectRouter)
				this.$store
					.dispatch("project/getProjectInfo", this.$route.params.projectId)
					.then(() => {
						if (this.env === "xinhua") {
							// 如果是环境，就不显示遗传办管理菜单
							this.routerMap = arr.filter(item =>
								item.name.indexOf("projectGenetic")
							)
						} else {
							// 如果不是环境 不显示研究成果填写菜单
							this.routerMap = arr.filter(
								item => item.name !== "projectAchieveFill"
							)
							// 或者 非 但没有开启遗传办，也不显示遗传办管理菜单
							if (!this.projectInfo.isOpenHgc) {
								this.routerMap = this.routerMap.filter(item =>
									item.name.indexOf("projectGenetic")
								)
							}
						}
					})
			} else if (this.isInProject || !this.routerMap.length) {
				this.isInProject = false
				const routerArr = [...this.systemRouter]
				// if (this.env === 'xinhua') {
				//   // 环境，不展示遗传办报表 (只有非环境，展示遗传办报表模块;)
				//   routerArr = this.systemRouter.map((item) => {
				//     if (item.name === 'report' && item.children && item.children.length) {
				//       item.children = item.children.filter(child => child.name !== 'GeneticSourceReport' && child.name !== 'GeneticLicenseReport');
				//     }
				//     return item;
				//   });
				// } else {
				//   // 非环境，不展示研究成果模块 （只有环境展示研究成果模块；）
				//   routerArr = this.systemRouter.filter((item) => {
				//     if (item.name === 'achievement') return false;
				//     if (item.name === 'setting' && item.children && item.children.length) {
				//       item.children = item.children.filter(child => child.name.indexOf('settingAchieveForm') === -1);
				//     }
				//     return true;
				//   });
				// }
				// if (this.env !== 'shenzhenrenmin') {
				//   // 非人民医院，不展示项目信息登记表；（只有人民医院环境 展示 报表中心-项目信息登记报表 模块）
				//   routerArr = routerArr.map((item) => {
				//     if (item.name === 'report' && item.children && item.children.length) {
				//       item.children = item.children.filter(child => child.name !== 'reportProjectRegisterInfo');
				//     }
				//     return item;
				//   });
				// }
				const Path = this.$route.path
				this.routerMap = routerArr.filter(
					item => Path.indexOf(item.path) === 0 || item.name === "navigation"
				)
			}
		},
		// 项目内路由，将':projectId'替换为相应的projectId
		replaceProjectId(data) {
			return data.map(item => {
				Object.assign(item, {
					path: item.path.replace(":projectId", this.$route.params.projectId)
				})
				if (item.children && item.children.length) {
					Object.assign(item, {
						children: this.replaceProjectId(item.children)
					})
				}
				return item
			})
		},
		// 路由跳转
		selectMenu(name) {
			if (this.$route.params.projectId) {
				this.$router.push({ name, projectId: this.$route.params.projectId })
			} else {
				if (name === "reportProjectSchedule") {
					// 点击项目流程进度报表时，清空缓存的查询信息
					sessionStorage.setItem("reportParams", "")
				}
				this.$router.push({ name })
			}
		},
		activeClass(submenu) {
			return `ivu-menu-item${this.currentRouterName.indexOf(submenu.name) > -1 ? " ivu-menu-item-active ivu-menu-item-selected" : ""}`
		},
		activeChildClass(submenu) {
			return `ivu-menu-submenu${this.currentRouterName.indexOf(submenu.name) > -1 ? " ivu-menu-child-item-active ivu-menu-item-active" : ""}`
		}
	}
}
</script>
