<template>
	<Layout class="global-layout">
		<GlobalHeader></GlobalHeader>
		<Layout class="global-layout__main">
			<template v-if="routerName != 'navigation'">
				<GlobalSlideMenu></GlobalSlideMenu>
				<Content
					class="global-layout__content"
					style="width: calc(100% - 240px); height: calc(100% - 40px)"
				>
					<MainLayout v-if="!isProject"></MainLayout>
					<ProjectLayout v-else></ProjectLayout>
				</Content>
			</template>
			<template v-else>
				<Content
					class="global-layout__content navigation-page"
					style="width: calc(100% - 240px); height: calc(100% - 40px)"
				>
					<MainLayout v-if="!isProject"></MainLayout>
					<ProjectLayout v-else></ProjectLayout>
				</Content>
			</template>
		</Layout>
	</Layout>
</template>

<script>
import "./layout.less"
import { mapState } from "vuex"
import CtmsWebSocket from "@/utils/websocket"
import MainLayout from "@/components/Layouts/MainLayout.vue"
import ProjectLayout from "@/components/Layouts/ProjectLayout.vue"
import GlobalHeader from "./GlobalHeader/index.vue"
import GlobalSlideMenu from "./GlobalSlideMenu/index.vue"

export default {
	name: "GlobalLayout",
	components: {
		GlobalSlideMenu,
		GlobalHeader,
		MainLayout,
		ProjectLayout
	},
	data() {
		return {
			CtmsWebSocket: null,
			connectTimer: null
		}
	},
	computed: {
		...mapState("permission", ["systemMenuPermissions"]),
		...mapState(["enumerate", "environment"]),
		...mapState("user", ["info", "projectStepInfo", "processList"]),
		isProject() {
			return this.$route.path.indexOf("/project/") > -1
		},
		routerName() {
			return this.$route.name
		}
	},
	mounted() {
		const lastTime = this.info.pwdUpdateDate
		const isShow = sessionStorage.getItem("isFirstShow")
		if (isShow === "1") {
			if (lastTime) {
				const updateTime = new Date(lastTime).getTime()
				const nowTime = new Date().getTime()
				const dateTime = 1000 * 60 * 60 * 24 // 每一天的毫秒数
				// 计算出两个日期的天数差
				const dury = Math.floor((nowTime - updateTime) / dateTime)
				if (dury >= 90) {
					this.$Notice.warning({
						title: "更换密码提醒",
						desc: "您已经超过90天没有修改密码，请尽快更换新密码！",
						duration: 5
					})
					sessionStorage.setItem("isFirstShow", "0") // 用来控制更换密码提醒，只在登录后提示一次。
				}
			} else {
				this.$Notice.warning({
					title: "更换密码提醒",
					desc: "您的密码是初始密码，请尽快更换新密码！",
					duration: 5
				})
				sessionStorage.setItem("isFirstShow", "0") // 用来控制更换密码提醒，只在登录后提示一次。
			}
		}
		// 获取系统配置
		this.$store.dispatch("config/getSystemConfig")
		// 获取所有枚举
		if (!Object.keys(this.enumerate).length) {
			this.$store.dispatch("getEnumerate")
		}
		// 获取项目阶段
		if (!this.projectStepInfo.length) {
			this.$store.dispatch("user/getProjectStepInfo")
		}
		// 获取流程信息
		if (!this.processList.length) {
			this.$store.dispatch("user/getProcessList")
		}
		if (!this.environment) {
			this.$store.dispatch("getEnv")
		}
		/* this.$nextTick(() => {
      const that = this;
      this.CtmsWebSocket = new CtmsWebSocket('/join-socket',
        {
          subscribeList: [
            {
              url: `/topic/${this.$store.state.user.info.id}/messages`,
              callback: (res) => {
                if (res.status === 2) {
                  this.$Notice.open({
                    title: '您收到新消息',
                    desc: res.data.message,
                    duration: 5,
                  });
                }
              },
            },
          ],
          onStateChange: (response) => {
            // response.status 0 连接成功，4连接错误
            switch (response.status) {
              case 4:
                this.connectTimer = setTimeout(that.CtmsWebSocket.initWebSocket, 60 * 1000);
                break;
              default:
                break;
            }
          },
        });
    }); */
	},
	beforeDestroy() {
		// this.CtmsWebSocket.disConnection();
		clearInterval(this.connectTimer)
	}
}
</script>

<style scoped lang="less">
.global-layout {
	.navigation-page {
		width: 100% !important;
		height: 100% !important;
		left: 0 !important;
		margin: 0;
	}
}
</style>
