import SockJS from "sockjs-client"
import Stomp from "stompjs"
// 开发用
const isDev = true
export default class CtmsWebSocket {
	constructor(url, options = {}) {
		this.socketClient = null
		this.options = options
		this.url = url
		if (isDev) {
			this.initWebSocket()
		}
	}

	initWebSocket() {
		/** subscribeList 订阅消息列表 type Array
		 *onStateChange：连接状态、数据、message type Function
		 *params  url建立连接地址 type Object* */
		const { params = {}, subscribeList = [] } = this.options

		// 建立连接对象（还未发起连接）
		const socket = new SockJS(this.url)
		this.options.onStateChange({
			status: 0,
			message: "建立连接对象成功",
			data: null
		})
		// 获取 STOMP 子协议的客户端对象
		this.socketClient = Stomp.over(socket)
		this.socketClient.connect(
			params,
			() => {
				// 连接成功
				this.options.onStateChange({
					status: 1,
					message: "连接成功",
					data: null
				})
				// 订阅消息
				if (subscribeList.length) {
					subscribeList.forEach(item => {
						this.socketClient.subscribe(item.url, res => {
							const returnData = {}
							try {
								// status 为2表示成功
								Object.assign(returnData, {
									status: 2,
									data: JSON.parse(res.body)
								})
							} catch (err) {
								// 返回一个状态
								Object.assign(returnData, {
									status: 3,
									data: null,
									message: err
								})
							}
							item.callback(returnData)
						})
					})
				}
			},
			err => {
				// 连接错误
				this.options.onStateChange({
					status: 4,
					message: err,
					data: null
				})
			}
		)
	}

	// 发送消息
	sendMessage(url, message) {
		const { headers = {} } = this.options
		this.socketClient.send(url, headers, message)
	}

	// 断开连接
	disConnection() {
		if (this.socketClient) {
			this.socketClient.disconnect()
		}
	}
}
