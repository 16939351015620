<template>
	<div class="global__logo">
		<div class="img">
			<router-link to="/navigation">
				<img src="../../../assets/login_images/logo.png" />
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: "SlideLogo",
	computed: {
		isXinhua() {
			return this.$store.state.environment === "xinhua"
		}
	}
}
</script>

<style scoped lang="less">
.global__logo {
	.ivu-layout-header {
		line-height: 1;
	}
	height: 64px;
	padding-top: 12px;
	overflow: hidden;
	position: relative;
	z-index: 2;
	float: left;
	width: 185px;
	&.xinhua {
		width: 310px;
	}
	.img {
		padding-top: 4px;
		img {
			height: 35px;
			vertical-align: top;
		}
	}
}
</style>
