<template>
	<Sider :width="200" class="global-slide">
		<SlideMenu></SlideMenu>
	</Sider>
</template>

<script>
import SlideMenu from "./SlideMenu.vue"

export default {
	name: "index",
	components: {
		SlideMenu
	}
}
</script>

<style scoped lang="less">
.global-slide {
	position: absolute;
	overflow: hidden;
	background-color: #fff;
	height: 100%;
	flex: none !important;
}
</style>
